section#search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

section#search > div.search-container {
    position: absolute;
    width: 80%;
    min-height: 100px;
    top: -80px;
    background: #242565;
    box-shadow: 0 10px 50px rgba(61, 55, 241, 0.25);
    border-radius: 20px;
    z-index: 10;
    padding: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

div.search-input-container > div > label {
    color: #ffffff !important;
}

.search-container > div > .MuiInput-root, .MuiInputBase-input, .MuiIconButton-root {
    color: #ffffff !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #ffffff !important;
}

.MuiOutlinedInput-notchedOutline:hover {
    border-color: #e7e5e5 !important;
}

.search-input-container {
    flex: 0.3;
}

.MuiFormControl-root {
    width: 100%;
}

@media (max-width: 480px) {
    section > div.search-container {
        flex-wrap: wrap;
    }

    .search-input-container {
        flex: auto;
        margin-bottom: 20px;
    }
}

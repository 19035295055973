header {
    background-image: url('../img/background.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 40px;
}

header > div.background {
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(118.98deg, #ED4690 -2.11%, #5522CC 63.58%);
    mix-blend-mode: normal;
    opacity: 0.9;
    z-index: 10;
}

.header-box {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.header-box > div {
    flex: 0.7;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header-box > div > a {
    margin: 0 10px;
}

.header-box > div > a.button-login {
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    max-height: 41px;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
}

.header-box-content {
    padding: 40px 0;
    display: flex;
}

.header-box-content > div.box-content {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-box-content > div.box-content > img {
    width: 100%;
}

.header-box-content > div.box-content > h1 {
    font-size: 40px;
    color: #ffffff;
    font-weight: 600;
}

.header-box-content > div.box-content > p {
    font-size: 20px;
    color: #ffffff;
    font-weight: 200;
    margin: 20px 0;
}

.nav-bar-mobile {
    display: none !important;
}

@media (min-width: 481px) and (max-width: 1024px) {
    .header-box-content {
        flex-direction: column;
    }

    .header-box-content > div.box-content > img {
        width: 50%;
    }

    .header-box-content > div.box-content {
        flex: 1;
    }
}

@media (max-width: 480px) {
    .header-box-content {
        flex-direction: column;
    }

    .header-box-content > div.box-content > img {
        width: 100%;
    }

    .header-box-content > div.box-content {
        flex: 1;
    }

    .nav-bar {
        display: none !important;
    }

    .nav-bar-mobile {
        display: flex !important;
    }
}

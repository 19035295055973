.event-box-date {
    flex-direction: column;
    flex: 0.3;
}

.event-box-date > small {
    color: #3D37F1;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.event-box-date > p.date-text {
    font-size: 28px;
    font-weight: 700;
    margin: 5px 0;
}

.event-box-text > h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

p.event-box-text-description {
    font-size: 14px;
    font-weight: 300;
    margin: 5px 0;
}

.event-box-text {
    flex: 0.7;
}

.event-box-content {
    padding: 10px 20px;
    display: flex;
}

.event-box-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.event-box {
    box-shadow: 0 19px 48px rgba(119, 115, 170, 0.1);
    border-radius: 18px;
    width: calc(25% - 20px);
    margin: 10px;
    cursor: pointer;
}

.event-box:hover {
    box-shadow: 0 19px 48px rgba(119, 115, 170, 0.3);
}

.event-box > img {
    width: 100%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    object-fit: cover;
    height: 197px;
}

.main-section {
    margin-top: 50px;
    padding: 100px 0;
}

.main-section > div > h2 {
    font-size: 40px;
    font-weight: 700;
    color: #242565;
    margin: 0 10px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

footer {
    background-color: #0A075F;
    padding: 50px 0;
    color: #ffffff;
}

hr {
    margin: 40px 0;
    border-color: #4C4D8B;
}

@media (max-width: 480px) {
    .main-section {
        margin-top: 200px;
    }
}

@media (min-width: 661px) and (max-width: 900px) {
    .event-box {
        width: calc(33% - 20px);
    }
}

@media (min-width: 401px) and (max-width: 660px) {
    .event-box {
        width: calc(50% - 20px);
    }
}

@media (max-width: 400px) {
    .event-box {
        width: calc(100% - 20px);
    }
}
